import slugify from 'slugify'
import { useEffect, useState, useRef } from 'react'

import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import type { StoreSort } from '@generated/graphql'
import { ProductItemLoading } from 'src/components/molecule/ProductItemLoading'
import { useGetReviews } from 'src/hooks/useGetReviews'

import { ProductItem } from '../../molecule/ProductItem'

import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'
import { useSession } from 'src/sdk/session'

interface ShelfProps {
  title: string
  items: number
  columns: number
  nome: string
  tipo: string
  sort: StoreSort
  slidesPerView?: number
  style?: string
  location?: string
  pdp?: boolean
}

export const ShelfVtex = (props: ShelfProps) => {
  const [loading, setLoading] = useState(false)
  const type = props.tipo
  let selectedFacets: Array<{ key: string; value: string }> = []
  const nome = props.nome.replace(/['"]/g, '-')

  switch (type) {
    case 'Coleção':
      selectedFacets = [{ key: 'productClusterIds', value: nome }]
      break

    case 'Marca':
      selectedFacets = [
        { key: 'brand', value: slugify(nome.toLowerCase().trim()) },
      ]
      break

    case 'Departamento':
      selectedFacets = [
        { key: 'category-1', value: slugify(nome.toLowerCase().trim()) },
      ]
      break

    case 'Categoria':
      selectedFacets = [
        { key: 'category-2', value: slugify(nome.toLowerCase().trim()) },
      ]
      break

    case 'CompreJunto':
      selectedFacets = [{ key: 'buy', value: nome }]
      break

    default:
      break
  }

  const { cookie } = useSession()

  const data = useProductsQuery({
    first: Number(props.items),
    after: '0',
    sort: 'discount_desc',
    term: '',
    selectedFacets,
    cookie,
  })

  useEffect(() => {
    if (data) {
      setLoading(true)
    }
  }, [data])

  const prodIds: any[] = []

  data?.edges.forEach((item) => {
    const prodId: any = item.node.isVariantOf.productGroupID

    prodIds.push(prodId)
  })

  const reviews = useGetReviews(prodIds)

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  const itensToShow = Number(props.pdp && props.location !== 'footer' ? 3 : 5)

  return (
    <div data-container-shelf>
      <section className="container my-3 vtexShelf shelf mdMax:mx-auto">
        <h3
          className={`${
            props.style
              ? props.style
              : "after:bg-pink-light after:h-3 after:absolute after:block after:bottom-[-2px] after:w-full after:z-[0] flex text-[1.3rem] leading-6 w-max max-w-full text-center mx-auto mt-[30px] px-3 mb-[22px] justify-center relative after:content-[''] break-word md:text-[2rem] md:leading-8"
          }`}
        >
          <span className="z-[1]">{props.title}</span>
        </h3>
        {!loading ? (
          <div className="shelfVtex flex flex-row pl-3 pr-3 pb-4 container overflow-hidden animate-pulse">
            {Array(itensToShow)
              .fill('')
              .map((_, i) => {
                return (
                  <ProductItemLoading key={`itemload_${i}_${props.location}`} />
                )
              })}
          </div>
        ) : (
          <div className="carrossel shelfVtex flex flex-row pl-3 pr-3 pb-4 lg:min-h-[464px]">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={15}
              slidesPerView={props.slidesPerView ? props.slidesPerView : 5}
              slidesPerGroup={props.slidesPerView ? props.slidesPerView : 5}
              loop
              pagination={{ clickable: true }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              // onInit={() => hideLoad()}
              autoplay={false}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                425: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                // >= 768px
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1024: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                1280: {
                  slidesPerView: props.slidesPerView ? props.slidesPerView : 5,
                  slidesPerGroup: props.slidesPerView ? props.slidesPerView : 5,
                },
              }}
            >
              {data?.edges.map((item, key) => {
                const prodId = item.node.isVariantOf.productGroupID

                return (
                  <SwiperSlide key={`slide_${prodId}_${key}`}>
                    <ProductItem
                      key={item.node.id}
                      item={item.node}
                      skus={item.node.offers.offers}
                      type="vtex"
                      review={reviews[prodId]}
                      style="xl:w-[216px]"
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <div ref={prevRef} className="carrossel_buttons prev">
              <CarrosselArrow direction="prev" />
            </div>
            <div ref={nextRef} className="carrossel_buttons next">
              <CarrosselArrow direction="next" />
            </div>
          </div>
        )}
      </section>
    </div>
  )
}
