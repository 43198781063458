import { useRef } from 'react'

import { ProductItem } from 'src/components/molecule/ProductItem'
import { ProductItemLoading } from 'src/components/molecule/ProductItemLoading'
import { useGetReviews } from 'src/hooks/useGetReviews'

import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useGetShelfPercycle } from 'src/hooks/useGetShelfPercycle'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'

interface ShelfPercycleProps {
  placement: string
  location?: string
  pdp?: boolean
}

interface SanitizationProps {
  title: string
  id: string
  brand: string
  link: string
  imageLink: string
  salePrice: {
    value: number
  }
  price: {
    value: number
  }
  sellers: string
}

export const ShelfPercycle = (props: ShelfPercycleProps) => {
  const { placement } = props

  const request = useGetShelfPercycle(placement)
  const products = request?.ShelfPercycle?.items ?? []
  const isOffline = request?.ShelfPercycle === null
  const productIds: any[] = []

  products.forEach((product) => {
    if (product?.id) {
      productIds.push(product.id)
    }
  })

  const reviews = useGetReviews(productIds)

  const sanitization = (item: SanitizationProps) => {
    return {
      slug: item.link,
      sku: '',
      name: item.title,
      gtin: '',
      id: item.id,
      brand: {
        brandName: item.brand,
        name: item.brand,
      },
      isVariantOf: {
        productGroupID: item.id,
        name: item.title,
      },
      image: [
        {
          url: item.imageLink.replace('-200-200/', '-300-300/'),
          alternateName: item.title,
        },
      ],
      offers: {
        lowPrice: Number(item.salePrice.value),
        offers: [
          {
            price: Number(item.salePrice.value),
            listPrice: Number(item.price.value),
            quantity: 1,
            seller: {
              identifier: item.sellers,
            },
          },
        ],
      },
    }
  }

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  const itensToShow = Number(props.pdp && props.location !== 'footer' ? 3 : 5)

  if (isOffline) {
    console.warn('Nenhum produto patrocinado retornado')

    return <></>
  }

  return (
    <div data-container-shelf>
      <section className="container my-3 percycleShelf shelf mdMax:mx-auto">
        <h3
          className={`${
            products.length < 1
              ? 'animate-pulse after:bg-slate-400 after:h-3 after:absolute after:block after:top-0 after:w-full after:z-[0] w-fit text-transparent'
              : 'after:bg-pink-light after:h-3 after:absolute after:block after:bottom-[-2px] after:w-full after:z-[0]'
          } flex text-[1.3rem] leading-6 w-max max-w-full text-center mx-auto mt-[30px] px-3 mb-[22px] justify-center relative after:content-[''] break-word md:text-[2rem] md:leading-8`}
        >
          <span className="z-[1]">Produtos Patrocinados</span>
        </h3>
        {products.length < 1 ? (
          <div className="flex flex-row pl-3 pr-3 pb-4 container overflow-hidden animate-pulse">
            {Array(itensToShow)
              .fill('')
              .map((_, i) => {
                return (
                  <ProductItemLoading key={`itemload_${i}_${props.location}`} />
                )
              })}
          </div>
        ) : (
          <div
            className="carrossel flex flex-row pl-3 pr-3 pb-4 lg:min-h-[464px]"
            data-content-carrossel
          >
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={15}
              slidesPerView={itensToShow}
              slidesPerGroup={itensToShow}
              loop
              pagination={{ clickable: true }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              // onInit={() => hideLoad()}
              autoplay={false}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                425: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                // >= 768px
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1024: {
                  slidesPerView: props.pdp ? 3 : 4,
                  slidesPerGroup: props.pdp ? 3 : 4,
                },
                1280: {
                  slidesPerView: itensToShow,
                  slidesPerGroup: itensToShow,
                },
              }}
            >
              {products?.map((item: any) => {
                const i = sanitization(item)

                return (
                  <SwiperSlide key={`slide_${i.id}`}>
                    <ProductItem
                      key={i.id}
                      item={i}
                      skus={
                        item?.customFields?.skus
                          ? item?.customFields?.skus?.length
                          : 1
                      }
                      type="percycle"
                      review={reviews[i.id]}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>

            <div ref={prevRef} className="carrossel_buttons prev">
              <CarrosselArrow direction="prev" />
            </div>
            <div ref={nextRef} className="carrossel_buttons next">
              <CarrosselArrow direction="next" />
            </div>
          </div>
        )}
      </section>
    </div>
  )
}
