import { gql } from '@faststore/graphql-utils'

import type { ReviewsQueryQuery } from '@generated/graphql'
import { productRates } from 'src/utils'

import { useQuery } from '../sdk/graphql/useQuery'

export const ReviewsQuery = gql`
  query ReviewsQuery($codes: [String]) {
    ReviewsTrust(codes: $codes) {
      products_rates {
        average
        count
        product_code
        product_name
      }
    }
  }
`

/**
 * Hook de Reviews
 */
export const useGetReviews = (codes: string[]) => {
  const { data } = useQuery<ReviewsQueryQuery>(ReviewsQuery, { codes })

  return productRates(data?.ReviewsTrust?.products_rates ?? [])
}
