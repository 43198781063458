import { BuyButton } from 'src/components/ui/Button/BuyButton'
import { responsivePicture } from 'src/components/ui/Image/responsiveImage'
import type { ProductsRates } from '@generated/graphql'

import { FlagDiscount } from '../FlagDiscount'
import { TrustVoxRate } from '../TrustVoxRate'
import { ProductPrice } from '../ProductPrice'
import { WishListBtn } from '../WishListBtn'
import { ProductPicture } from './ProductPicture'
import Link from 'next/link'

interface InfoItemProduct {
  slug: string
  sku: string
  name: string
  gtin: string
  description: string
  id: string
  brand: {
    name: string
    brandName: string
  }
  isVariantOf: {
    productGroupID: string
    name: string
  }
  image: Array<{
    url: string
    alternateName: string
  }>
  offers: {
    lowPrice: number
    offers: Array<{
      price: number
      listPrice: number
      quantity: number
      seller: {
        identifier: string
      }
    }>
  }
}

interface ProductItemProps {
  /**
   * Informacao do produto, seja qual foi a fonte de dados no formato `InfoItemProduct`
   *
   */
  item: InfoItemProduct | any
  /**
   * Usado para tratar urls de acordo com a origem dos dados
   * vtex | rich | percycle
   */
  type: string
  /**
   * Informa se o Componente está sendo renderizado na pagina de busca ou não, para adequar o layout
   */
  busca?: boolean
  /**
   * Parametro usado no subcomponente <BuyButton>, deve ser desconsiderado na maioria das vezes
   */
  page?: string
  /**
   * Essa prop é o index do loop. Serve para carregar as imagens em lazy ou não.
   * Se o indexLoop for menor que 4, ele coloca o parametro loading={'eager' ou 'lazy'}
   */
  indexLoop?: number
  /**
   * Serve para mostrar a informação [+ opções] no componente, para sinalizar que existe
   * mais skus para escolha
   */
  skus?: [] | any
  /**
   * Reviews da TrustVox
   */
  review?: ProductsRates

  style?: string
}

/**
 * Componente monta o item da vitrine. Componente agnóstico, não está preso a origem do dado. Pode ser usado para origem vtex, rich, etc
 *
 * @param item - array com os dados do item que componhe a vitrine
 * @returns HTML - monta o item na vitrine
 */
// prettier-ignore
export const ProductItem = ({
  item,
  type,
  busca,
  page,
  indexLoop,
  skus,
  review,
  style,
}: ProductItemProps) => { // NOSONAR
  let link = ''
  let domain = ''

  if (type === 'vtex') {
    const sku = item.sku
    const _replace = `-${sku}`

    link = `/${item.slug.replace(_replace, '')}/p`
    domain = 'epocacosmeticosqa.vtexassets.com'
  } else if (type === 'rich') {
    // se o link for da rich deve colocar
    // um parametro de redirecionamento como true
    link = `${item.slug}&redirect=true`
    domain = 'epocacosmeticos.vteximg.com.br'
  } else {
    link = item.slug
    domain = 'www.epocacosmeticos.com.br'
  }

  const pictureProps = responsivePicture({
    src: item?.image[0].url,
    domain,
  })

  const classItem = busca
    ? 'bg-white relative last:mr-0 shadow-md p-1 pb-0 border-gray-400 group hover:bg-[#fef8f8] transition-all h-full'
    : `bg-white relative h-full ${
        style ?? 'min-w-[80%] md:min-w-[30%] lg:w-[235px]'
      } last:mr-0 shadow-md p-1 pb-0 border-gray-400 group`

  const lazyImg = !!indexLoop && indexLoop <= 4

  const divSku = () => {
    return (
      <>
        {busca ? (
          <div>
            {item.offers.offers.length > 1 ? (
              <div className="bg-[#f1f1f1] text-center relative">
                <p className="text-[#4F4F4F] leading-[30px]">
                  +{item.offers.offers.length - 1}
                  {item.offers.offers.length === 2 ? ' opção' : ' opções'}
                </p>
              </div>
            ) : (
              <div>
                <p className="leading-[30px] text-transparent">Apenas 1 item</p>
              </div>
            )}
          </div>
        ) : (
          <>
            {skus && skus.length > 1 ? (
              <div className="bg-[#f1f1f1] text-center relative">
                <p className="text-[#4F4F4F] leading-[30px]">
                  +{skus.length - 1}
                  {skus.length > 2 ? ' opções' : ' opção'}
                </p>
              </div>
            ) : (
              <div>
                <p className="leading-[30px] text-transparent">Apenas 1 item</p>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  // usado para os favoritos
  const productId: string = item.isVariantOf.productGroupID

  return (
    <div
      className={`productItemComponent ${classItem}`}
      data-testid="productItemComponent"
      data-product-item
      data-product-id={productId}
      data-product-price={item.offers.offers[0].price ?? 0}
    >
      <div className="absolute w-full pointer-events-none h-full top-0 left-0 lg:group-hover:bg-[rgba(255,255,255,0.6)] z-[2]" />
      <a
        href={link ?? ''}
        className="block "
        title={item.isVariantOf.name}
        tabIndex={-1}
      >
        {item.offers.offers.length > 0 && (
          <FlagDiscount
            type="shelf"
            listPrice={item.offers.offers[0].listPrice}
            price={item.offers.offers[0].price}
          />
        )}
      </a>
      <div className="relative">
        {type === 'percycle' ? (
          ''
        ) : (
          <WishListBtn
            sku={item.sku}
            type="shelf"
            productId={productId}
            productName={item.isVariantOf.name}
          />
        )}
        <a
          href={link ?? ''}
          className="block "
          title={item.isVariantOf.name}
          tabIndex={-1}
        >
          <ProductPicture
            className="w-full h-auto"
            loading={lazyImg ? 'eager' : 'lazy'}
            alt={item.image[0].alternateName ? item.image[0].alternateName : ''}
            src={pictureProps?.src}
            sources={pictureProps?.srcSet}
          />
        </a>
      </div>
      <Link
        href={link ?? ''}
        className="block "
        title={item.isVariantOf.name}
        tabIndex={-1}
      >
        <a>
          {divSku()}
          <BuyButton type={page ?? 'shelf'} slug={item.slug} text="comprar" />
          <div className="text-sm md:text-[16px] brand mt-3 mb-1 font-bold uppercase h-[17px] whitespace-nowrap text-ellipsis overflow-hidden max-w-[210px]">
            {item.brand.brandName}
          </div>
          <div
            className={`name ${
              busca ? 'text-xs md:text-sm' : 'text-xs lg:text-sm'
            } h-[14px] md:h-[35px] lg:h-[43px] overflow-hidden mb-2 md:mb-1 text-[#4F4F4F]`}
          >
            {item.isVariantOf.name}
          </div>
          <TrustVoxRate review={review} />

          {item.offers.offers.length > 0 ? (
            <ProductPrice {...item.offers.offers[0]} />
          ) : (
            <div style={{ height: '89px', marginBottom: '12px' }} />
          )}
        </a>
      </Link>
    </div>
  )
}
