export interface ResponsiveImageProps {
  src: string
  sizes: number[]
  domain?: string
}

export interface ResponsivePictureProps {
  src: string
  domain?: string
}
/**
 * Função que gera o srcSet responsivo para as imagens
 * @param src - é o caminho original da imagem
 * @param initialSize - é o tamanho inicial da imagem que será exibido no mobile
 * @param domain -  é o domínio de entrada que o src pertence
 * retorna um objeto contento o src e srcSet, em caso de erro retorna apenas o src
 */
export const responsiveImage = ({
  src,
  sizes,
  domain = 'epocacosmeticos.vtexassets.com',
}: ResponsiveImageProps) => {
  const baseUrl = `//${domain}/arquivos/ids/`
  const initialUrl = src
    .replace('https:', '')
    .replace('http:', '')
    .replace(baseUrl, '')

  const arrImg = initialUrl.split('/')
  const id = arrImg.shift()?.split('-').at(0)?.replace('-', '')

  const srcSet: string[] = []

  sizes.forEach((size) => {
    const sercSet = `${baseUrl}${id}-${size}-${size}/${arrImg.join(
      '/'
    )} ${size}w`

    srcSet.push(sercSet)
  })

  try {
    return {
      src,
      srcSet: srcSet.join(', '),
    }
  } catch (error) {
    return { src }
  }
}

export const responsivePicture = ({
  src,
  domain = 'epocacosmeticos.vtexassets.com',
}: ResponsivePictureProps) => {
  const baseUrl = `//${domain}/arquivos/ids/`
  const initialUrl = src
    .replace('https:', '')
    .replace('http:', '')
    .replace(baseUrl, '')

  const arrImg = initialUrl.split('/')
  const id = arrImg.shift()?.split('-').at(0)?.replace('-', '')

  const srcSet: Array<{ media: number; src: string }> = []

  const sizes = [320, 410, 500, 600, 768, 1024, 1280]

  sizes.forEach((size) => {
    const _src = `${baseUrl}${id}-${size}-${size}/${arrImg.join('/')}`

    srcSet.push({
      media: size,
      src: _src,
    })
  })

  return {
    src,
    srcSet,
  }
}
