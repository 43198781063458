export interface ProductItemLoadingProps {
  classesLi?: string
}

export const ProductItemLoading = ({ classesLi }: ProductItemLoadingProps) => {
  const defaultClassLi =
    'relative w-[235px] min-w-[235px] smMax:min-w-[191px] smMax:w-[191px] smMax:h-[430px] lg:h-[430px] smMax:mr-1 mr-3 last:mr-0 shadow-md p-1 pb-0 border-gray-400'

  const _classesLi = classesLi ?? defaultClassLi

  return (
    <div className={_classesLi}>
      <div className="image w-full h-[219px] bg-slate-200" />
      <div className="content mt-2">
        <div className="brand h-3 bg-slate-400 rounded mb-3" />
        <div className="text h-1 bg-slate-300 rounded my-1" />
        <div className="text h-1 bg-slate-300 rounded" />
        <div className="rate w-[69px] h-[15px] relative bg-rate bg-[left_top_-5px] my-4" />
        <div className="price h-1 w-[45px] bg-slate-300 rounded" />
        <div className="price h-3 w-[50%] bg-slate-300 rounded my-1" />
        <div className="price h-2 w-[70px] bg-slate-300 rounded" />
      </div>
    </div>
  )
}
