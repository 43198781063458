import { ProductItem } from 'src/components/molecule/ProductItem'
import { useRichPlacemments } from 'src/hooks/useRichPlacemments'
import { getStorageRcs } from 'src/utils'
import { useGetReviews } from 'src/hooks/useGetReviews'

import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useRef } from 'react'

import { ProductItemLoading } from 'src/components/molecule/ProductItemLoading'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'

interface ShelfRichProps {
  placement: string
  location?: string
  pdp?: boolean
  fpb?: string
  noTitle?: boolean
  style?: string
}
export interface SanitizationProps {
  name: string
  id: string
  brand: string
  clickURL: string
  imageURL: string
  salePriceRangeCents: number[]
  priceRangeCents: number[]
  attributes: {
    skuJsonLength: string[]
    skusIds: string[]
    sellers: string[]
  }
}

export const ShelfRich = (props: ShelfRichProps) => {
  const { placement, fpb, noTitle, style } = props

  const _rcs = getStorageRcs(placement)

  const options = {
    fpb: fpb || '',
    placement,
    rcs: _rcs,
  }

  const itensToShow = Number(props.pdp && props.location !== 'footer' ? 3 : 5)

  const data = useRichPlacemments(options)
  const prodIds: any[] = []

  const products =
    data?.RichPlacements?.placements
      ?.map((item: any) => {
        return item.recommendedProducts.map((product: any) => {
          prodIds.push(product?.id)

          return {
            ...product,
            ...product.attributes,
          }
        })
      })
      .flat() ?? []

  const shelfTitle = data?.RichPlacements?.placements
    ? data?.RichPlacements?.placements[0]?.strategyMessage
    : ''

  const reviews = useGetReviews(prodIds)

  const sanitization = (item: SanitizationProps) => {
    return {
      slug: item?.clickURL,
      name: item?.name,
      gtin: '',
      id: item?.id,
      brand: {
        brandName: item?.brand,
        name: item?.brand,
      },
      isVariantOf: {
        productGroupID: item?.id,
        name: item?.name,
      },
      image: [
        {
          url: item?.imageURL,
          alternateName: item?.name,
        },
      ],
      offers: {
        lowPrice: item?.salePriceRangeCents[0] / 100,
        offers: [
          {
            price: item?.salePriceRangeCents[0] / 100,
            listPrice: item?.priceRangeCents[0] / 100,
            quantity: 1,
          },
        ],
      },
    }
  }

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  const isLoading = products.length < 1

  return (
    <div data-container-shelf>
      {isLoading ? (
        <div className="flex flex-row pl-3 pr-3 pb-4 container overflow-hidden animate-pulse">
          {Array(itensToShow)
            .fill('')
            .map((_, i) => {
              return (
                <ProductItemLoading key={`itemload_${i}_${props.location}`} />
              )
            })}
        </div>
      ) : (
        <section className="container my-3 richShelf shelf mdMax:mx-auto">
          {!noTitle && shelfTitle && (
            <h3 className="after:bg-pink-light after:h-3 after:absolute after:block after:bottom-[-2px] after:w-full after:z-[0] flex text-[1.3rem] leading-6 w-max max-w-full text-center mx-auto mt-[30px] px-3 mb-[22px] justify-center relative after:content-[''] break-word md:text-[2rem] md:leading-8">
              <span className="z-[1] relative">{shelfTitle}</span>
            </h3>
          )}
          <div className="carrossel flex flex-row pl-3 pr-3 pb-4 lg:min-h-[464px]">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={15}
              loop
              pagination={{ clickable: true }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              // onInit={() => hideLoad()}
              autoplay={false}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                425: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                // >= 768px
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1024: {
                  slidesPerView: props.pdp ? 3 : 4,
                  slidesPerGroup: props.pdp ? 3 : 4,
                },
                1280: {
                  slidesPerView: itensToShow,
                  slidesPerGroup: itensToShow,
                },
              }}
            >
              {products?.map((item: any, key: any) => {
                const i = sanitization(item)
                const prodId = item.id

                return (
                  <SwiperSlide key={`slide_${prodId}_${key}`}>
                    <ProductItem
                      key={key}
                      item={i}
                      skus={item?.attributes?.skusIds ?? null}
                      type="rich"
                      review={reviews[prodId]}
                      style={style}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>

            <div ref={prevRef} className="carrossel_buttons prev">
              <CarrosselArrow direction="prev" />
            </div>
            <div ref={nextRef} className="carrossel_buttons next">
              <CarrosselArrow direction="next" />
            </div>
          </div>
        </section>
      )}
    </div>
  )
}
