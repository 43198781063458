interface ProductPriceProps {
  price: number
  listPrice: number
  favorito?: boolean
}

/**
 * Recebe valores decimais e formata em preço de e preço por. Também calcula parcelas
 * @param props - array com price e listprice
 * @returns HTML
 */
export const ProductPrice = (props: ProductPriceProps) => {
  const precoDe = props.listPrice
  const precoPor = props.price
  const multiplier = 40

  const formatPrice = (n: number) => {
    return new Intl.NumberFormat('pb-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(n)
  }

  const installments = (n: number) => {
    let install = ''

    if (n >= multiplier * 2) {
      let mult = Math.trunc(n / multiplier)
      let number = 0

      if (mult > 1 && mult <= 10) {
        number = n / mult
      } else if (mult > 10) {
        number = n / 10
        mult = 10
      }

      const value = formatPrice(Math.floor(number * 10 ** 2) / 10 ** 2)

      install = `${mult}x ${value}`
    }

    return install
  }

  return (
    <>
      <div className={`${props.favorito && 'mt-3'}`}>
        <span className="text-sm md:text-sm block line-through h-3 text-[#5e5e5e]">
          {precoDe !== precoPor ? formatPrice(precoDe) : ''}
        </span>
      </div>
      <div
        className={`price ${
          props.favorito ? 'h-[45px]' : 'mb-2 h-[73px]'
        } mt-1`}
      >
        <div>
          <span className="text-[16px] md:text-[24px] font-bold">
            {formatPrice(precoPor)}
          </span>
          {!props.favorito && (
            <p className=" text-sm md:text-[16px] text-[#5e5e5e]">
              {installments(precoPor)}
            </p>
          )}
        </div>
      </div>
    </>
  )
}
