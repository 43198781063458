import type { ProductsRates } from '@generated/graphql'

export interface TrustVoxRateProps {
  review?: ProductsRates
}

/**
 * Molécula funcional que recebe productID, consulta Trustvox e monta as estrelas de avaliação
 * @param review - objeto de avaliação da trustvox
 * @returns HTML
 */

export const TrustVoxRate = ({ review }: TrustVoxRateProps) => {
  const percent = (n: number) => {
    return Math.round((n * 100) / 5)
  }

  if (!review) {
    return <div className="h-[22px]" />
  }

  return (
    <div className="rate xs:flex text-xs">
      <div
        className="rate__gray w-[105px] h-[22px] relative bg-rate bg-[left_top_-5px] mr-[3px]"
        style={{ backgroundPosition: '0 0' }}
      >
        <div
          className="absolute h-[22px] block bg-rate bg-[left_bottom_-1px]"
          style={{
            width: `${review?.average ? percent(review.average) : 0}%`,
            backgroundPosition: '0 -23px',
          }}
        />
      </div>
      <p className="mt-auto mb-auto">({review?.count})</p>
    </div>
  )
}
