import { useContext } from 'react'
import { SimpleButton } from 'src/components/ui/Button/SimpleButton'
import { StoreContext } from 'src/providers/StoreProvider'
import style from './feedbackrequest.module.scss'

export interface FeedbackRequestProps {
  productName: string
  skuName: string
  success: boolean
  isRemoved?: boolean
}

export const FeedbackRequest = ({
  productName,
  skuName,
  success,
  isRemoved,
}: FeedbackRequestProps) => {
  const { setModalFavOpen } = useContext(StoreContext)
  let title = ''
  let text = ''
  const actionTitle = isRemoved ? 'removido' : 'adicionado'
  const actionSubtitle = isRemoved ? 'removido dos' : 'adicionado aos'

  if (success) {
    title = `Produto ${actionTitle} com sucesso!`
    text = `O Produto <strong>${productName} - ${skuName}</strong> foi ${actionSubtitle} seus favoritos.`
  } else {
    title = `Erro ao ${actionTitle} o produto`
    text = `O Produto <strong>${productName} - ${skuName}</strong> não pode ser ${actionSubtitle} seus favoritos.`
  }

  return (
    <div className={`${style.wrapper_login_fav} ${style.modal_success}`}>
      <h4>{title}</h4>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <SimpleButton onClick={() => setModalFavOpen(false)} data-btn-fechar>
        Fechar
      </SimpleButton>
    </div>
  )
}
