interface BuyButtonProps {
  type: string
  slug: string
  text: string
  className?: string
}

/**
 * Componente para montar o botão comprar de acordo com as propriedades recebidas
 * @param { type, slug, text } - tipo de uso (vitrine ou PDP), slug para montar link e texto do btn
 * @returns HTML
 */

export const BuyButton = ({ type, slug, text, className }: BuyButtonProps) => {
  let link = ''

  if (type === 'shelf') {
    link = `${slug}/p`
  } else if (type === 'pdp') {
    link = 'invocaFuncaoMineCart'
  } else {
    link = slug
  }

  if (type === 'shelf') {
    return (
      <div className="buyButton cursor-pointer bg-pink rounded-[3px] uppercase text-white font-bold opacity-0 transition-opacity duration-300 hidden w-[calc(100%-16px)] absolute lg:group-hover:flex lg:left-[8px] lg:group-hover:opacity-100 lg:h-[72px] lg:justify-center lg:items-center lg:text-base lg:top-[230px] z-[3]">
        {text}
      </div>
    )
  }

  // na pagina de busca o top para o botao sofre uma variação para lg e xl
  if (type === 'search') {
    return (
      <div className="buyButton bg-pink rounded text-white uppercase font-bold opacity-0 transition-opacity duration-300 hidden  w-[calc(100%-16px)] absolute lg:group-hover:flex lg:left-[8px] lg:group-hover:opacity-100 lg:h-[80px] lg:justify-center lg:items-center lg:text-base xl:mt-[-15px] lg:top-[230px] z-[3]">
        {text}
      </div>
    )
  }

  return (
    <>
      <a href={link} className={className}>
        {text}
      </a>
    </>
  )
}
