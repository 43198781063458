import styles from './flag-discount.module.scss'

/**
 * Monta a flag de desconto.
 * @param {type, listPrice e price} - type(shelf ou pdp): o type define o css
 * @returns html
 */
interface FlagDiscountProps {
  type: string
  listPrice: number
  price: number
}

export const FlagDiscount = ({ type, listPrice, price }: FlagDiscountProps) => {
  const discountValue = Math.round((price / listPrice) * 100 - 100) * -1

  if (discountValue === 0 || price === listPrice) {
    return <></>
  }

  return (
    <>
      <div className={styles.FlagDiscount} data-type={type}>
        <p>
          <strong>{`${discountValue}%`}</strong>
          <span>Off</span>
        </p>
      </div>
    </>
  )
}
