import { gql } from '@faststore/graphql-utils'
import { useCookies } from 'react-cookie'

import { useSession } from 'src/sdk/session'
import type { RichPlacemmentsQueryQuery } from '@generated/graphql'

import { useQuery } from '../sdk/graphql/useQuery'
import { HashRich } from 'src/utils/RichRelevance'

export const fragment = gql`
  fragment Rich_Placemments on RichPlacements {
    placements {
      strategyMessage
      placement
      placementType
      recommendedProducts {
        brand
        id
        clickURL
        attributes {
          skuJson
          skuJsonLength
          skusIds
          sellers
        }
        name
        imageURL
        salePriceRangeCents
        priceRangeCents
        productURL
      }
    }
  }
`

export const query = gql`
  query RichPlacemmentsQuery($fpb: String, $placement: String, $rcs: String) {
    RichPlacements(fpb: $fpb, placement: $placement, rcs: $rcs) {
      rcs
      status
      viewGuid
      ...Rich_Placemments
    }
  }
`

interface RichPlacementsProps {
  fpb: string
  placement: string
  rcs: string | null | undefined
}

/**
 * Hook de placemments da rich
 */
export const useRichPlacemments = ({
  fpb,
  placement,
  rcs,
}: RichPlacementsProps): RichPlacemmentsQueryQuery | undefined => {
  const [cookies] = useCookies()
  const { person } = useSession() // pega a seção do usuário
  const userId = person?.email ? HashRich(person?.email) : ''

  const infoVariables = {
    fpb: fpb || '',
    placement,
    rcs,
    sessionId: cookies.VtexRCMacIdv7,
    userId,
  }

  const { data } = useQuery<RichPlacemmentsQueryQuery, RichPlacementsProps>(
    query,
    infoVariables
  )

  return data
}
