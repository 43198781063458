import { v4 as uuidkey } from 'uuid'

export interface ProductPictureProps {
  className?: string
  loading: 'eager' | 'lazy' | undefined
  alt: string
  src: string
  sources: Array<{ media: number; src: string }> | undefined
}

export const ProductPicture = ({
  className,
  loading,
  alt,
  src,
  sources,
}: ProductPictureProps) => {
  const keyloop = uuidkey()

  return (
    <picture key={keyloop}>
      {sources?.map((item, i) => {
        return (
          <source
            media={`(max-width: ${item.media}px)`}
            srcSet={item.src}
            key={`${keyloop}_${i}`}
          />
        )
      })}
      <img
        className={className}
        loading={loading}
        alt={alt}
        src={sources ? sources[0].src : src}
        width={200}
        height={200}
      />
    </picture>
  )
}
