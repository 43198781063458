import { useContext, useEffect, useState } from 'react'
import { ButtonLink } from 'src/components/ui/Button'
import { StoreContext } from 'src/providers/StoreProvider'
import getSkuByProductId, { getCsrf, getPersonStorage } from 'src/utils'
import type { FavsStorageProps } from 'src/utils/Favoritos'
import {
  addFavoritos,
  getFavsFromStorage,
  removeFavoritos,
} from 'src/utils/Favoritos'
import { FavModalSelector } from '../FavModalSelector/FavModalSelector'
import { FeedbackRequest } from '../FeedbackRequestFav'
import style from './wishllist-button.module.scss'

/**
 * Monta btn para adicionar e remover item à lista de desejos
 * @param {sku, type} - type (shelf ou pdp): de acordo com type muda o css
 * @returns HTML
 */

interface WishListBtnProps {
  sku: string
  type: string
  productId: string
  productName: string
}

export const WishListBtn = ({
  sku,
  type,
  productId,
  productName,
}: WishListBtnProps) => {
  const [isFavored, setIsFavored] = useState<boolean>(false)
  const [skuFavored, setSkuFavored] = useState<FavsStorageProps[]>([])

  const person = getPersonStorage()

  const {
    modalFavOpen,
    setModalFavOpen,
    setContentModalFav,
    setModalLoaderOpen,
    setFavoritesCount,
    favoritesCount,
  } = useContext(StoreContext)

  useEffect(() => {
    const _favsLocal = getFavsFromStorage()
    const _favored = _favsLocal.filter((item) => item.productId === productId)

    setIsFavored(_favored.length > 0)
    setSkuFavored(_favored)
  }, [favoritesCount])

  const goToLogin = () => {
    return (
      <div className={style.wrapper_login_fav}>
        <h4>Você precisa fazer login para alterar seus favoritos</h4>
        <ButtonLink href="/login" onClick={() => setModalFavOpen(false)}>
          Faça Login
        </ButtonLink>
      </div>
    )
  }

  // prettier-ignore
  const handlerClick = async () => {// NOSONAR
    setModalLoaderOpen(true) // abre o loader

    if (!person) {
      // se não tiver logado
      setModalFavOpen(!modalFavOpen)
      setContentModalFav(goToLogin())
      setModalLoaderOpen(false) // fecha o loader
    } else {
      const userId = person.id
      const csrf = getCsrf(userId, person.email)
      const skus = await getSkuByProductId(productId ?? '')

      if (isFavored) {
        // se ja tiver nos favoritos, remove
        if (skus.length > 1) {
          if (skuFavored.length > 1) {
            const arrSku = skuFavored.map((i) => i.skuId)
            const skusFilt = skus.filter((s) => arrSku.includes(s.sku))

            const favModalProps = {
              skus: skusFilt,
              productName,
              productId,
              csrf,
              userId,
              isToRemove: true,
            }

            setContentModalFav(<FavModalSelector {...favModalProps} />)

            setModalFavOpen(true)
            setModalLoaderOpen(false)
          } else {
            const data = [
              {
                skuId: skuFavored[0].skuId,
                productId,
              },
            ]

            const removeFavResp = await removeFavoritos({ csrf, userId, data })

            setFavoritesCount()

            setContentModalFav(
              <FeedbackRequest
                productName={productName}
                skuName={skus[0].name}
                success={removeFavResp.status === 'ok'}
                isRemoved
              />
            )
          }
        } else {
          const data = [
            {
              skuId: sku ?? skus[0].sku,
              productId,
            },
          ]

          const removeFavResp = await removeFavoritos({ csrf, userId, data })

          setFavoritesCount()

          setContentModalFav(
            <FeedbackRequest
              productName={productName}
              skuName={skus[0].name}
              success={removeFavResp.status === 'ok'}
              isRemoved
            />
          )

          setModalFavOpen(true)
          setModalLoaderOpen(false)

          setTimeout(() => setModalFavOpen(false), 6000)
        }

        setModalLoaderOpen(false) // fecha o loader
      } else {
        const favModalProps = {
          skus,
          productName,
          productId,
          csrf,
          userId,
        }

        if (skus.length > 1) {
          setContentModalFav(<FavModalSelector {...favModalProps} />)
          setModalFavOpen(true)
          setModalLoaderOpen(false)
        } else {
          const data = [
            {
              skuId: sku ?? skus[0].sku,
              productId,
            },
          ]

          const addFavResp = await addFavoritos({ csrf, userId, data })

          setFavoritesCount()

          setContentModalFav(
            <FeedbackRequest
              productName={productName}
              skuName={skus[0].name}
              success={addFavResp.status === 'ok'}
            />
          )
          setModalFavOpen(true)
          setModalLoaderOpen(false)

          setTimeout(() => setModalFavOpen(false), 6000)
        }
      }
    }
  }

  return (
    <>
      <div
        onClick={handlerClick}
        onKeyUp={handlerClick}
        role="button"
        tabIndex={0}
        data-type={type}
        data-favored={isFavored}
        className={`${style.wishlist_btn} add-fav bt-product-${sku} 
        ${
          type === 'shelf'
            ? 'absolute left-[8px] z-[2] bottom-[8px] w-[36px] h-[36px] rounded-full bg-silver flex items-center justify-center'
            : ''
        }`}
        title="Adicionar à lista de favoritos"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-[40px] p-[5px] w-[40px]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#E70D91"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </div>
    </>
  )
}
