import { gql } from '@faststore/graphql-utils'
import type {
  GetShelfPercycleQuery,
  GetShelfPercycleQueryVariables,
} from '@generated/graphql'

import { useQuery } from '../sdk/graphql/useQuery'

export const ShelfPercycleQuery = gql`
  query GetShelfPercycle($placement: String) {
    ShelfPercycle(placement: $placement) {
      items {
        salePrice {
          formattedValue
          value
          integerPart
          decimalPart
          currencySymbol
          currencyCode
          thousandSeparator
          decimalSeparator
        }
        brand
        link
        price {
          formattedValue
          value
          integerPart
          decimalPart
          currencySymbol
          currencyCode
          thousandSeparator
          decimalSeparator
        }
        installment {
          months
          amount {
            formattedValue
            value
            integerPart
            decimalPart
            currencySymbol
            currencyCode
            thousandSeparator
            decimalSeparator
          }
        }
        imageLink
        title
        description
        categories
        discount {
          value
          symbol
          formattedValue
        }
        customFields {
          skus {
            sku
            specs {
              skuSize
            }
          }
        }
        px
        id
        seller
      }
    }
  }
`

/**
 * Hook de Reviews
 */
export const useGetShelfPercycle = (placement: string) => {
  const { data } = useQuery<
    GetShelfPercycleQuery,
    GetShelfPercycleQueryVariables
  >(ShelfPercycleQuery, { placement })

  return data
}
