import { useContext, useState } from 'react'
import { SimpleButton } from 'src/components/ui/Button/SimpleButton'
import { StoreContext } from 'src/providers/StoreProvider'
import { addFavoritos, removeFavoritos } from 'src/utils/Favoritos'
import { FeedbackRequest } from '../FeedbackRequestFav'
import style from './favmodalselector.module.scss'

interface skusProps {
  sku: string
  name: string
}
export interface FavModalSelectorProps {
  skus: skusProps[]
  productName: string
  productId: string
  csrf: string
  userId: string
  isToRemove?: boolean
}

export const FavModalSelector = ({
  skus,
  productName,
  productId,
  csrf,
  userId,
  isToRemove,
}: FavModalSelectorProps) => {
  const [skuSelected, setSkuSelected] = useState<skusProps>()
  const {
    setFavoritesCount,
    setContentModalFav,
    setModalFavOpen,
    setModalLoaderOpen,
  } = useContext(StoreContext)

  const selectFav = (sku: skusProps) => {
    setSkuSelected(sku)
  }

  const modifyFavs = async () => {
    const data = [
      {
        skuId: skuSelected?.sku ?? '',
        productId,
      },
    ]

    let favResp: any = {}

    if (isToRemove) {
      favResp = await removeFavoritos({ csrf, userId, data })
    } else {
      favResp = await addFavoritos({ csrf, userId, data })
    }

    setFavoritesCount()

    setContentModalFav(
      <FeedbackRequest
        productName={productName}
        skuName={skuSelected?.name ?? ''}
        success={favResp.status === 'ok'}
        isRemoved={isToRemove}
      />
    )
    setModalFavOpen(true)
    setModalLoaderOpen(false)

    setTimeout(() => setModalFavOpen(false), 6000)
  }

  return (
    <div className={style.favmodalselector}>
      <h2>
        Escolha o produto para {isToRemove ? 'remover dos' : 'adicionar aos'}{' '}
        favoritos
      </h2>
      <h3>{productName}</h3>

      <div data-skuselector-wapper>
        {skus.map((sku) => {
          return (
            <div
              key={sku.sku}
              data-sku={sku.sku}
              data-sku-selected={sku.sku === skuSelected?.sku}
              onClick={() => selectFav(sku)}
              onKeyPress={() => selectFav(sku)}
              role="none"
            >
              {sku.name}
            </div>
          )
        })}
      </div>

      <SimpleButton
        data-button-add-fav
        onClick={modifyFavs}
        onKeyPress={modifyFavs}
        role="none"
      >
        {isToRemove ? 'Remover dos' : 'Adicionar aos'} favoritos
      </SimpleButton>
    </div>
  )
}
