import type { PropsWithChildren } from 'react'
import { useRef, useState } from 'react'

import { Navigation, Pagination, A11y, Autoplay, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { CarrosselArrow } from 'src/components/molecule/CarrosselArrow'

interface CarrosselProps {
  slidesToShow: number
  dots?: boolean
  autoplay?: boolean
  autoplaySpeed?: number
  children: PropsWithChildren<any>
  classname: string
  slidesToShowMobile?: number
  overRideClasses?: boolean
}

export const Carrossel = (props: CarrosselProps) => {
  const [loadSlide, setLoadSlide] = useState(false)
  const {
    autoplay,
    children,
    dots,
    slidesToShow,
    classname,
    slidesToShowMobile,
    overRideClasses,
  } = props

  const classesSection = overRideClasses
    ? classname
    : `container md:mx-auto relative ${classname}`

  const enableAutoplay = autoplay ? { delay: 5000 } : false

  const enableDots = dots ? { clickable: true } : false

  const classesLoader = !loadSlide
    ? 'container md:min-h-[360px] sm:min-h-[250px] min-h-[200px] '
    : ''

  const hideLoad = () => {
    setTimeout(() => setLoadSlide(true), 500)
  }

  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  return (
    <section className={`carrossel ${classesLoader} ${classesSection}`}>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y, Autoplay, Controller]}
        spaceBetween={50}
        slidesPerView={slidesToShow}
        slidesPerGroup={slidesToShow}
        pagination={enableDots}
        onInit={() => hideLoad()}
        autoplay={enableAutoplay}
        breakpoints={{
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          425: {
            slidesPerView: slidesToShowMobile ?? 1,
            slidesPerGroup: slidesToShowMobile ?? 1,
          },
          // >= 768px
          768: {
            slidesPerView: slidesToShow ?? 1,
            slidesPerGroup: slidesToShow ?? 1,
          },
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
      >
        {children.map((child: any, index: any) => {
          return <SwiperSlide key={`slide_${index}`}>{child}</SwiperSlide>
        })}
      </Swiper>

      <div ref={prevRef} className="carrossel_buttons prev">
        <CarrosselArrow direction="prev" />
      </div>
      <div ref={nextRef} className="carrossel_buttons next">
        <CarrosselArrow direction="next" />
      </div>

      {!loadSlide && (
        <div className="container h-full w-full animate-pulse bg-slate-200 absolute inset-0 z-[5]" />
      )}
    </section>
  )
}
